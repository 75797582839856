var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-config-provider',{attrs:{"locale":_vm.local}},[_c('div',{staticClass:"order-wrap"},[_c('div',{staticClass:"order-title"},[_vm._v("保险查询")]),_c('div',{staticClass:"order-main"},[_c('div',{staticClass:"order-form"},[_c('a-form',{attrs:{"form":_vm.searchForm,"layout":"inline"}},[_c('a-form-item',{staticClass:"flight-city-form-item",attrs:{"label":"首航段","label-align":"left"}},[_c('FlightCityGroupSelector',{ref:"citySelector",attrs:{"depCity":_vm.cities.depCity,"arrCity":_vm.cities.arrCity},on:{"depChange":_vm.onDepChange,"arrChange":_vm.onArrChange}})],1),_c('a-form-item',{attrs:{"label":"日期类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.dateType',
                {
                  initialValue: '',
                } ]),expression:"[\n                'searchForm.dateType',\n                {\n                  initialValue: '',\n                },\n              ]"}],staticStyle:{"width":"100px"},attrs:{"size":"small"}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v("航班日期")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("购保日期")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("退保日期")])],1)],1),_c('a-form-item',[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['searchForm.rangeTime']),expression:"['searchForm.rangeTime']"}],attrs:{"size":"small","show-time":"","format":"YYYY-MM-DD"}})],1),_c('a-form-item',{attrs:{"label":"投保状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.orderStatus',
                {
                  initialValue: '',
                } ]),expression:"[\n                'searchForm.orderStatus',\n                {\n                  initialValue: '',\n                },\n              ]"}],staticStyle:{"width":"100px"},attrs:{"size":"small"}},[_c('a-select-option',{attrs:{"value":""}},[_vm._v("全部")]),_c('a-select-option',{attrs:{"value":"HP"}},[_vm._v("出保中")]),_c('a-select-option',{attrs:{"value":"PF"}},[_vm._v("已投保")]),_c('a-select-option',{attrs:{"value":"WP"}},[_vm._v("等待支付")]),_c('a-select-option',{attrs:{"value":"XX"}},[_vm._v("已取消")]),_c('a-select-option',{attrs:{"value":"SB"}},[_vm._v("投保失败")]),_c('a-select-option',{attrs:{"value":"RH"}},[_vm._v("退保中")]),_c('a-select-option',{attrs:{"value":"RB"}},[_vm._v("退保失败")]),_c('a-select-option',{attrs:{"value":"RF"}},[_vm._v("已退保")])],1)],1),_c('a-form-item',{attrs:{"label":"旅客证件号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.certNo'
              ]),expression:"[\n                'searchForm.certNo'\n              ]"}],attrs:{"size":"small","placeholder":"证件号"}})],1),_c('a-form-item',{attrs:{"label":"票号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.ticketNo',
                { rules: [{ message: '请输入票号' }] } ]),expression:"[\n                'searchForm.ticketNo',\n                { rules: [{ message: '请输入票号' }] },\n              ]"}],attrs:{"size":"small","placeholder":"票号"}})],1),_c('a-form-item',{attrs:{"label":"机票订单号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.outOrderNo'
              ]),expression:"[\n                'searchForm.outOrderNo'\n              ]"}],attrs:{"size":"small","placeholder":"机票订单号"}})],1),_c('a-form-item',{attrs:{"label":"保险订单号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.orderNo' ]),expression:"[\n                'searchForm.orderNo',\n              ]"}],attrs:{"size":"small","placeholder":"保险订单号"}})],1),_c('a-form-item',{attrs:{"label":"旅客姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'searchForm.passengerName'
              ]),expression:"[\n                'searchForm.passengerName'\n              ]"}],attrs:{"size":"small","placeholder":"旅客姓名"}})],1),_c('a-form-item',[_c('a-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.onSeach}},[_vm._v(" 查询 ")]),_c('a-button',{attrs:{"size":"small","type":"primary","ghost":""},on:{"click":_vm.onReset}},[_vm._v(" 重置 ")])],1)],1)],1),_c('div',{staticClass:"order-table"},[_c('a-table',{attrs:{"scroll":{ x: 'max-content' },"columns":_vm.columns,"data-source":_vm.tableData,"pagination":_vm.pageOptions,"rowKey":function (record, index) {
              return index;
            },"loading":_vm.loading,"bordered":""},on:{"change":_vm.onTableChange},scopedSlots:_vm._u([{key:"index",fn:function(text, record, index){return [_vm._v(" "+_vm._s(index)+" ")]}},{key:"outOrderNo",fn:function(text){return [_c('router-link',{attrs:{"to":{
                path: '/orderManager/orderDetail',
                query: { id: text },
              }}},[_vm._v(_vm._s(text))])]}},{key:"orderNo",fn:function(text){return [_c('router-link',{attrs:{"to":{
                path: '/orderManager/insuranceDetail',
                query: { id: text },
              }}},[_vm._v(_vm._s(text))])]}},{key:"segment",fn:function(text, record){return [_vm._v(" "+_vm._s(record.depCityCn)+"-"+_vm._s(record.arrCityCn)+" ")]}},{key:"tradeNo",fn:function(tradeNo, row){return [_c('div',[_vm._v(_vm._s(row.payment.tradeNo))])]}},{key:"orderStatus",fn:function(orderStatus){return [_c('div',[_vm._v(_vm._s(_vm._f("insuranceDetailStatusFilter")(orderStatus)))])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }