<template>
  <a-config-provider :locale="local">
    <div class="order-wrap">
      <div class="order-title">保险查询</div>
      <div class="order-main">
        <div class="order-form">
          <a-form :form="searchForm" layout="inline">
            <a-form-item
              class="flight-city-form-item"
              label="首航段"
              label-align="left"
            >
              <FlightCityGroupSelector
                ref="citySelector"
                :depCity="cities.depCity"
                :arrCity="cities.arrCity"
                @depChange="onDepChange"
                @arrChange="onArrChange"
              />
            </a-form-item>
            <a-form-item label="日期类型">
              <a-select
                size="small"
                style="width: 100px"
                v-decorator="[
                  'searchForm.dateType',
                  {
                    initialValue: '',
                  },
                ]"
              >
                <a-select-option value="0">航班日期</a-select-option>
                <a-select-option value="1">购保日期</a-select-option>
                <a-select-option value="2">退保日期</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-range-picker
                size="small"
                v-decorator="['searchForm.rangeTime']"
                show-time
                format="YYYY-MM-DD"
              />
            </a-form-item>
            <a-form-item label="投保状态">
              <a-select
                size="small"
                style="width: 100px"
                v-decorator="[
                  'searchForm.orderStatus',
                  {
                    initialValue: '',
                  },
                ]"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="HP">出保中</a-select-option>
                <a-select-option value="PF">已投保</a-select-option>
                <a-select-option value="WP">等待支付</a-select-option>
                <a-select-option value="XX">已取消</a-select-option>
                <a-select-option value="SB">投保失败</a-select-option>
                <a-select-option value="RH">退保中</a-select-option>
                <a-select-option value="RB">退保失败</a-select-option>
                <a-select-option value="RF">已退保</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="旅客证件号">
              <a-input
                size="small"
                placeholder="证件号"
                v-decorator="[
                  'searchForm.certNo'
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item label="票号">
              <a-input
                size="small"
                placeholder="票号"
                v-decorator="[
                  'searchForm.ticketNo',
                  { rules: [{ message: '请输入票号' }] },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item label="机票订单号">
              <a-input
                size="small"
                placeholder="机票订单号"
                v-decorator="[
                  'searchForm.outOrderNo'
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item label="保险订单号">
              <a-input
                size="small"
                placeholder="保险订单号"
                v-decorator="[
                  'searchForm.orderNo',
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item label="旅客姓名">
              <a-input
                size="small"
                placeholder="旅客姓名"
                v-decorator="[
                  'searchForm.passengerName'
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-button size="small" type="primary" @click="onSeach">
                查询
              </a-button>
              <a-button size="small" type="primary" ghost @click="onReset">
                重置
              </a-button>
              <!-- <a-button size="small"> 导出 </a-button> -->
            </a-form-item>
          </a-form>
        </div>
        <div class="order-table">
          <a-table
            :scroll="{ x: 'max-content' }"
            :columns="columns"
            :data-source="tableData"
            :pagination="pageOptions"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            :loading="loading"
            bordered
            @change="onTableChange"
          >
            <template slot="index" slot-scope="text, record, index">
              {{ index }}
            </template>
            <template slot="outOrderNo" slot-scope="text">
              <router-link
                :to="{
                  path: '/orderManager/orderDetail',
                  query: { id: text },
                }"
                >{{ text }}</router-link
              >
            </template>
            <template slot="orderNo" slot-scope="text">
              <router-link
                :to="{
                  path: '/orderManager/insuranceDetail',
                  query: { id: text },
                }"
                >{{ text }}</router-link
              >
            </template>
            <template slot="segment" slot-scope="text, record">
              {{ record.depCityCn }}-{{ record.arrCityCn }}
            </template>
            <template slot="tradeNo" slot-scope="tradeNo, row">
              <div>{{ row.payment.tradeNo }}</div>
            </template>
            <template slot="orderStatus" slot-scope="orderStatus">
              <div>{{ orderStatus | insuranceDetailStatusFilter }}</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import tableBaseMixin from '@/common/mixin/table-base-mixin'
import orderSearchMixin from '@/common/mixin/order-search-mixin'

// import { format } from '@/filters/time'

import FlightCityGroupSelector from '@/components/FightCityGroupSelector/FlightCityGroupSelector'
import { insuranceDetailStatusFilter } from '@/filters/insurance'

import { insuranceOrderPage } from '@/api/insurance'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    scopedSlots: { customRender: 'index' },
    align: 'center'
  },
  {
    title: '机票订单号',
    dataIndex: 'outOrderNo',
    scopedSlots: { customRender: 'outOrderNo' }
  },
  {
    title: '保险订单号',
    dataIndex: 'orderNo',
    scopedSlots: { customRender: 'orderNo' }
  },
  {
    title: '旅客姓名',
    dataIndex: 'passengerName'
  },
  {
    title: '票号',
    dataIndex: 'ticketNo'
  },
  {
    title: '保单号',
    dataIndex: 'insuranceNo'
  },
  {
    title: '购保金额',
    dataIndex: 'netFare'
  },
  {
    title: '航线',
    dataIndex: 'segment',
    scopedSlots: { customRender: 'segment' }
  },
  {
    title: '航班日期',
    dataIndex: 'depTime'
  },
  {
    title: '购保日期',
    dataIndex: 'createTime'
  },
  {
    title: '退保日期',
    dataIndex: 'clearTime'
  },
  {
    title: '保单状态',
    dataIndex: 'orderStatus',
    scopedSlots: { customRender: 'orderStatus' }
  }
]

export default {
  name: 'insuranceSearch',
  components: {
    FlightCityGroupSelector
  },
  mixins: [tableBaseMixin, orderSearchMixin],
  filters: {
    insuranceDetailStatusFilter
  },
  data () {
    return {
      searchForm: this.$form.createForm(this, { name: 'search_form' }),
      columns,
      cities: {
        depCity: '',
        arrCity: ''
      },
      params: {
        depCode: '',
        arrCode: '',
        startDepTime: '',
        endDepTime: '',
        startCreateTime: '',
        endCreateTime: '',
        startClearTime: '',
        endClearTime: '',
        orderStatus: '',
        certNo: '',
        ticketNo: '',
        outOrderNo: '',
        orderNo: '',
        passengerName: '',
        page: 0,
        size: 10
      },
      loading: false,
      data: []
    }
  },
  mounted () {
    this.fetchTableData()
  },
  methods: {
    async fetchTableData () {
      this.loading = true
      try {
        const result = await insuranceOrderPage(this.params)
        this.loading = false

        const { content, size, totalElements, number } = result
        // this.tableData = dataParse(content, this.params)
        this.tableData = content

        this.pageOptions.total = totalElements
        this.pageOptions.current = number + 1
        this.pageOptions.pageSize = size
      } catch (e) {
        this.loading = false
        this.$message.error(e.message)
      }
    },
    onSeach () {
      const fields = this.searchForm.getFieldsValue().searchForm
      const { cities } = this

      const params = {
        ...fields,
        depCode: cities.depCity,
        arrCode: cities.arrCity
      }

      const dateTypeTimeMap = {
        0: {},
        1: {},
        2: {}
      }

      if (fields.rangeTime) {
        dateTypeTimeMap[fields.dateType] = {
          start: fields.rangeTime[0]
            ? fields.rangeTime[0].format('YYYY-MM-DD 00:00:00')
            : '',
          end: fields.rangeTime[1]
            ? fields.rangeTime[1].format('YYYY-MM-DD 23:59:59')
            : ''
        }

        params.startDepTime = dateTypeTimeMap[0].start
        params.endDepTime = dateTypeTimeMap[0].end

        params.startCreateTime = dateTypeTimeMap[1].start
        params.endCreateTime = dateTypeTimeMap[1].end

        params.startClearTime = dateTypeTimeMap[2].start
        params.endClearTime = dateTypeTimeMap[2].end
      }

      this.params = {
        ...this.params,
        ...params
      }

      this.fetchTableData()
    },
    onTableChange (pagination) {
      this.params.page = pagination.current - 1
      this.params.size = pagination.pageSize

      this.fetchTableData()
    },
    onReset () {
      this.$refs.citySelector.reset()
      this.searchForm.resetFields()
    },
    onDepChange (city) {
      this.cities.depCity = city
    },
    onArrChange (city) {
      this.cities.arrCity = city
    }
  }
}
</script>

<style lang="less" scoped>
.flight-city-form-item {
  width: 300px;
}
</style>
