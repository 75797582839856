import { parseToFilters, parseToMap } from './util'

export const serviceType = [
    { value: 'INSURANCE', label: '保险' }
]
export const serviceTypeMap = parseToMap(serviceType)

// 明细状态orderStatus状态枚举
export const insuranceDetailStatus = [
    { value: "WP", label: "等待支付" },
    { value: "XX", label: "已取消" },
    { value: "HP", label: "出保中" },
    { value: "SB", label: "投保失败" },
    { value: "PF", label: "已投保" },
    { value: "RH", label: "退保中" },
    { value: "RF", label: "已退保" },
    { value: "RB", label: "退保失败" }
]
export const insuranceDetailStatusMap = parseToMap(insuranceDetailStatus)


// 主单状态status枚举
export const insuranceStatus = [
    {value: "WP", label: "等待支付"},
    {value: "HP", label: "出保中"},
    {value: "XX", label: "已取消"},
    {value: "PF", label: "已出保"}
]
export const insuranceStatusMap = parseToMap(insuranceStatus)