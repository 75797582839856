import { axios } from '@/utils/axios'

// import '@/mock'

// 前台操作保险索价
export async function insuranceOfferQuery (data) {
  try {
    const result = await axios({
      url: '/api/front/insurance/offer/query',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 保险订单取消
export async function insuranceOrderCancel (data) {
  try {
    const result = await axios({
      url: '/api/front/insurance/order/cancel',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 保险订单列表
export async function insuranceOrderPage (data) {
  try {
    const result = await axios({
      url: '/api/front/insurance/order/page',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 保险订单列表
export async function insuranceOrderDetail (data) {
  try {
    const result = await axios({
      url: '/api/front/insurance/order/detail',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 保险订单预订
export async function insuranceOrderPlace (data) {
  try {
    const result = await axios({
      url: '/api/front/insurance/order/place',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 保险订单退保
export async function insuranceOrderRefund (data) {
  try {
    const result = await axios({
      url: '/api/front/insurance/order/refund',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}
