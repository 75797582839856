import {
    serviceTypeMap,
insuranceDetailStatusMap,
insuranceStatusMap } from '@/dicts/insurance'
import { mapFilter } from './util'

export function serviceTypeFilter(value) {
    return mapFilter(value, serviceTypeMap)
}
export function insuranceDetailStatusFilter(value) {
    return mapFilter(value, insuranceDetailStatusMap)
}
export function insuranceStatusFilter(value) {
    return mapFilter(value, insuranceStatusMap)
}

